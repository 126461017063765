import React from "react";
import { graphql, HeadProps, PageProps } from "gatsby";
import { getImage, StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Card from "../features/chooseChapter/Card";
import { ChapterTerm } from "../types";
import Seo from "../features/seo/Seo";

interface ChooseChapter7thEditionData {
  allTaxonomyTerm7thEditionChapter: {
    nodes: ChapterTerm[];
  };
  allNode7thEditionVideo: {
    nodes: {
      path: {
        alias: string;
      };
      relationships: {
        field_7th_edition_chapter: {
          field_chapter_number: number;
        };
      };
    }[];
  };
}

const ChooseChapter7thEdition = (
  props: PageProps<ChooseChapter7thEditionData>
) => {
  // Create array of sample url per chapter.
  const sampleUrlPerChapter: string[] = [];
  props.data.allNode7thEditionVideo.nodes.forEach((sampleSolution) => {
    sampleUrlPerChapter[
      sampleSolution.relationships.field_7th_edition_chapter.field_chapter_number
    ] = sampleSolution.path.alias;
  });

  return (
    <Layout>
      <header className="xs:flex justify-center mt-8 mb-4 gap-4">
        <div className="text-center">
          <div className="bg-white inline-flex text-center border border-neutral-100 rounded-xl p-2 mb-4 md:p-4 shadow-lg">
            <StaticImage
              src="../images/cover--7th-edition.jpeg"
              alt="Giancoli 7th Edition textbook cover"
              placeholder="tracedSVG"
              loading="eager"
              width={140}
            />
          </div>
        </div>

        <h1 className="text-neutral-700 font-bold text-xl sm:text-2xl md:text-3xl sm:mt-10 text-center xs:text-left">
          Giancoli's Physics:
          <p>Principles with Applications, 7th Edition</p>
        </h1>
      </header>

      <p className="text-blue-600 xs:text-neutral-700 text-xl sm:text-2xl font-bold mb-8 xs:mb-4 text-center xs:text-left">
        Choose a chapter
      </p>
      <div className="grid grid-cols-1 xs:justify-items-center xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-8 md:gap-10">
        {props.data.allTaxonomyTerm7thEditionChapter.nodes.map((chapter) => {
          return (
            <Card
              url={sampleUrlPerChapter[chapter.field_chapter_number]}
              key={chapter.drupal_internal__tid}
              chapter={chapter}
            />
          );
        })}
      </div>
    </Layout>
  );
};

export default ChooseChapter7thEdition;

export const Head = (props: HeadProps) => {
  return (
    <Seo
      title="Choose a 7th Edition Chapter | Giancoli Answers"
      pathname={props.location.pathname}
      description="Choose solutions to a chapter in the 7th Edition of Giancoli's Physics: Principles with Applications."
    />
  );
};

export const query = graphql`
  {
    allNode7thEditionVideo(filter: { field_sample: { eq: true } }) {
      nodes {
        path {
          alias
        }
        relationships {
          field_7th_edition_chapter {
            field_chapter_number
          }
        }
      }
    }
    allTaxonomyTerm7thEditionChapter(sort: { fields: field_chapter_number }) {
      nodes {
        field_chapter_number
        field_number_of_solutions
        drupal_internal__tid
        description {
          value
        }
        field_thumbnail {
          alt
        }
        relationships {
          field_thumbnail {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 220, placeholder: TRACED_SVG)
              }
            }
          }
        }
      }
    }
  }
`;
